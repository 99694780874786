import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const getTmpToken = () => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/tmp-token`);
};

const getPlayToken = (vcode) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/play-token?vcode=${vcode}`);
};

const getVodUrl = (vcode) => {
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/vod-url?vcode=${vcode}`);
};

export const dogeBaseApi = {
  getTmpToken: getTmpToken,
  getPlayToken: getPlayToken,
  getVodUrl: getVodUrl,
};
